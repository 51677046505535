$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1600px
);

$primary: #51a0d2;
$secondary: #f6851f;

@import "../../node_modules/bootstrap5/scss/bootstrap.scss";

$body-bg: #f7f7f7;
$font-family-base: "Quicksand";
$breadcrumb-divider: "\f285";
$enable-negative-margins: true;

.custom-select {
	@extend .form-select
}

html[data-bs-theme=dark] {
	
	header,
	header .top-bar,
	.categories a,
	.parameters,
	.product-box,
	.product-card {
		background: var(--bs-body-bg);
	}
	
	.range {
		
		& .range-price-from input,
		& .range-price-to input {
			
			background: #e9e9e90a;
		}
	}
}

html[data-bs-theme=light] {
	
	.categories a,
	.product-box,
	.parameters,
	header,
	header .top-bar,
	.form-control,
	.form-select,
	.product-card {
		background: #fff;
	}
}

.btn-secondary,
.btn-outline-secondary:hover,
.btn-secondary:hover,
.btn-primary,
.btn-outline-primary:hover,
.btn-primary:hover {
	color: #fff;
}

.breadcrumb {
	font-size: 12px;
	margin-bottom: 0.5rem;
	overflow-x: auto;
	flex-wrap: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
	
	&::-webkit-scrollbar {
		display: none;
	}
	
	& .breadcrumb-item {
		align-items: center;
		text-wrap: nowrap;
	}
}
