// @font-face {
//   font-display: fallback;
//   font-family: 'Quicksand';
//   font-weight: 300;
//   src: url(./../fonts/Quicksand-300.woff2) format('woff2')
// }

@font-face {
  font-display: fallback;
  font-family: 'Quicksand';
  font-weight: 400;
  src: url(./../fonts/Quicksand-400.woff2) format('woff2')
}

@font-face {
  font-display: fallback;
  font-family: 'Quicksand';
  font-weight: 600;
  src: url(./../fonts/Quicksand-600.woff2) format('woff2')
}

$bronzeGrad: linear-gradient(-72deg, #732100, #ca7345 16%, #ca7345 21%, #ca7345 24%, #a14521 27%, #ca7345 36%, #ca7345 45%, #dd936ef0 60%, #ca7345 72%, #ca7345 80%, #ca7345 84%, #732100);
$goldGrad: linear-gradient(-72deg, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
$silverGrad: linear-gradient(-72deg, #dadada, #e6e6e6 16%, #e8e8e8 21%, #e3e3e3 24%, #dbdbdb 27%, #dedede 36%, #fff 45%, #fff 60%, #dedede 72%, #fff 80%, #dedede 84%, #a1a1a1);
$bgPrimaryOpacity: #51a0d22b;

:root {
  --gold-color-grad: #{$goldGrad};
  --silver-color-grad: #{$silverGrad};
  --bronze-color-grad: #{$bronzeGrad};
  --bg-primary-opacity: #{$bgPrimaryOpacity};
}

.bg-primary-opacity {
  background: var(--bg-primary-opacity);
}

html {
  height: 100vh;
}

body {
  position: relative;
  font-family: 'Quicksand', sans-serif;
  color: var(--bs-body-color);
  min-height: 100%;
}

.theme-sel::after {
  content: none;
}

.theme-sel .dropdown-toggle {
  color: var(--bs-body-color);
}

.td-none {
  text-decoration: none;
}

.bi-flip {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.vh-100 {
  height: 100vh;
}

body.loading .loading-ovl {
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.loading-ovl .spinner-border {
  color: var(--bs-primary);
  font-size: 35px;
  height: 125px;
  width: 125px;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  color: #fff;
}