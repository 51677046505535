@import "global";
@import "../scss/bootstrap5.scss";

.aaa {
	color: black;
}

@mixin scrollbar {
	overflow-y: auto;
	scrollbar-width: thin;
	
	&::-webkit-scrollbar-thumb {
		width: 10px;
		background: var(--bs-primary);
		border-radius: 5px;
	}
	
	&::-webkit-scrollbar {
		width: 10px;
	}
}

*,
*:focus,
*:hover {
	outline: none;
}

.single-row-text {
	-webkit-line-clamp: 1;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.breadcrumb-item {
	display: flex;
	align-items: center;
	
	&::before {
		font-family: "bootstrap-icons" !important;
		font-size: 10px;
	}
	
	a {
		transition: all .2s;
		text-decoration: none;
		color: #a4a4a4;
	}
	
	a:hover {
		color: #4c4c4c;
	}
}

html[data-bs-theme=dark] header {
	background: var(--bs-body-bg);
}

header {
	height: 94px;
	max-height: 94px;
	transition: all 0.2s;
	background: #fff;
	color: var(--bs-primary);
	
	.top-bar .bi {
		color: var(--bs-primary);
	}
	
	.scrolled {
		background-color: transparent;
		
		.logo {
			background-image: url("../../www/assets/logo-nt.png");
		}
	}
	
	& .logo {
		background-image: url("../../www/assets/logo-nt.png");
		height: 40px;
		width: 163px;
		position: relative;
		
		& .carousel {
			position: absolute;
			left: 33px;
			top: 31px;
			text-wrap: nowrap;
			white-space: nowrap;
			
			& .carousel-item {
				font-size: 10px;
				text-transform: uppercase;
			}
		}
	}
	
	// &.ac {
	//   backdrop-filter: blur(2px);
	
	//   & .logo {
	//     // background-image: url('../../www/assets/logo-ntw.png');
	
	//     & .carousel .carousel-item {
	//       color: #fff
	//     }
	//   }
	// }
	
	// &.ac.scrolled {
	//   & .logo {
	//     background-image: url('../../www/assets/logo-nt.png');
	
	//     & .carousel .carousel-item {
	//       color: unset
	//     }
	//   }
	// }
	
	/*&:not(.scrolled) {
	  max-height: 100px;
	  height: 100px;
	}*/
	
	// &.scrolled {
	//   background: var(--bs-body-bg);
	//   box-shadow: 0 4px 4px 0px #00000020;
	//   border-bottom: 1px solid var(--bs-primary);
	// }
}

.logo {
	display: inline-block;
	width: 300px;
	height: 64px;
	background-image: url("../../www/assets/logo.png");
	background-size: contain;
	background-repeat: no-repeat;
}

footer .logo {
	background-image: url("../../www/assets/logo-footer.png");
	width: 100%;
}

.main-content-box {
	z-index: 50;
	
}

.main-content {
	z-index: 55;
	
	.hasac {
		backdrop-filter: blur(5px) brightness(1.2);
	}
}

.hp-top {
	background-image: url("./../images/bg-hp.jpg");
	background-size: cover;
	color: #fff;
}

.hp-cats-cat {
	position: relative;
	
	&:hover {
		letter-spacing: 1px;
		
		a {
			background: #fff;
			color: #000;
		}
		
		.bi {
			background: var(--bs-secondary);
			color: #fff;
		}
	}
	
	a {
		display: block;
		background: #fff3;
		backdrop-filter: blur(4px);
		color: #fff;
		text-align: center;
		font-weight: bold;
		text-decoration: none;
		position: relative;
		transition: all 0.2s;
	}
	
	& .bi {
		transition: all 0.2s;
		color: var(--bs-primary);
		font-weight: bold;
		width: 30px;
		height: 30px;
		border-radius: 20px;
		background: #fff;
		position: absolute;
		left: calc(50% - 15px);
		top: -15px;
		line-height: 30px;
	}
}

.search-bar {
	form {
		//background: #f9f9f9;
		//border: 1px solid #cdcdcd;
		border-radius: 100px;
		//padding: 4px;
	}
	
	input {
		border: none;
		background: transparent;
		color: var(--bs-body-color);
	}
}

.search-results {
	overflow-y: auto;
	max-height: 75vh;
	
	.list-group-item:hover {
		background: var(--bs-primary);
		color: var(--bs-dark-text);
	}
}

#ocSearch {
	height: 170px;
}

#offCategories {
	background: var(--bs-body-bg);
}

#offCategories,
#offFilters {
	top: 94px;
	max-width: 70%;
}

.product-box {
	border: 1px solid #00000015;
	
	& img {
		max-height: 200px;
		height: 200px;
	}
}

.parameters {
	@include scrollbar;
	
	border: 1px solid #efefef;
	color: var(--bs-body-color);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	
	& > form > label {
		padding: 0 .5em;
	}
	
	& > form > label > select {
		margin-top: 5px;
	}
}

html[data-bs-theme=dark] {
	.product-card {
		&:not(.premium) .card-footer {
			background: var(--bs-dark-bg-subtle);
			color: var(--bs-body-color);
		}
		
		.product-price {
			color: var(--bs-body-color);
		}
		
		.product-buy {
			background: var(--bs-light-bg-subtle);
			color: var(--bs-body-color);
		}
		
		.card-img-top img {
			mix-blend-mode: normal;
		}
	}
}

#productPhotos {
	height: 450px;
	max-height: 450px;
	
	img {
		object-fit: contain;
	}
	
	& .carousel-control-prev-icon,
	& .carousel-control-next-icon,
	& .carousel-indicators button {
		filter: drop-shadow(1px 1px 2px black);
	}
	
	& .carousel-inner {
		height: 100%;
	}
}

.product-card {
	height: 100%;
	border-color: #dadada;
	background: #fff;
	
	& .bi-heart-fill {
		color: red;
	}
	
	& * {
		transition: all 0.2s;
	}
	
	&.premium {
		& .product-buy {
			border: none;
		}
		
		&:hover .product-buy {
			font-size: 104%;
		}
	}
	
	.badge {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 6px 10px;
		letter-spacing: 1px;
		color: #fff;
		
		&.badge-new {
			background: var(--bs-success);
		}
		
		&.badge-used {
			background: var(--bs-primary);
		}
		
		&.badge-broken {
			background: var(--bs-warning);
		}
	}
	
	.card-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
	}
	
	&.product-gold {
		border: 2px solid #bf953f;
		
		& .card-footer {
			background: $goldGrad;
		}
	}
	
	&.product-silver {
		$c: silver;
		border: 2px solid $c;
		
		& .card-footer {
			background: $silverGrad;
		}
	}
	
	&.product-bronze {
		border: 2px solid #924b29;
		
		& .card-footer {
			background: $bronzeGrad;
		}
	}
	
	& .favorite-btn {
		position: absolute;
		top: 5px;
		right: 5px;
		z-index: 10;
	}
	
	.product-buy {
		border: 1px solid #000;
		background: #fff;
		border-radius: 25px;
		color: #000;
		padding: 5px 20px;
		text-decoration: none;
		font-size: 14px;
		font-family: "Quicksand", Calibri, serif;
		font-weight: 600;
	}
	
	.product-price {
		font-size: 18px;
		font-weight: 600;
		color: #000;
	}
	
	.card-img-top {
		height: 200px;
		margin: 20px;
		width: auto;
		
		& img {
			object-fit: contain;
			height: 100%;
			width: 100%;
			mix-blend-mode: multiply;
		}
	}
	
	.card-body {
		text-align: center;
		color: var(--bs-body-color);
		
		h5 {
			font-weight: bold;
		}
		
		.product-desc {
			color: var(--bs-body-color);
			text-wrap: balance;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}
	
	&:not(.premium):hover {
		border-color: var(--bs-primary);
		
		.product-price {
			color: #fff;
		}
		
		.card-footer {
			background: var(--bs-primary);
		}
		
		.card-footer .product-buy {
			color: var(--bs-primary);
			border-color: #fff;
		}
	}
}

.product-title {
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-wrap: balance;
	font-size: 1rem;
	
	text-decoration: none;
	color: var(--bs-body-color);
	font-weight: 600;
}

.cat-btn {
	width: 100%;
	line-height: 3.5em;
	text-transform: uppercase;
	background: var(--bs-primary);
	border: none;
	font-weight: bold;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	padding: 0 1em;
	
	.bi {
		margin-right: 1.3em;
	}
}

html[data-bs-theme=dark] .categories,
html[data-bs-theme=dark] .parameters {
	border: 1px solid var(--bs-primary);
}

/*.categories, .parameters {
  overflow-y: auto;
  scrollbar-width: thin;
}*/

.filter-preview {
	position: fixed;
	bottom: 20px;
	width: 200px;
	font-size: 15px;
	align-self: center;
}

.categories {
	background: var(--bs-body-bg);
	
	@include scrollbar;
	
	& > a.open {
		background: rgba(81, 160, 210, 0.10);
	}
	
	& > a:hover,
	& > a.active {
		color: var(--bs-primary);
	}
	
	& > a > i:last-child {
		margin-left: auto;
	}
	
	& > * {
		padding: 0.7em 1em;
	}
	
	& > a {
		justify-content: space-between;
		display: flex;
		align-items: center;
		transition: all 0.2s;
		color: var(--bs-bs-list-group-color);
		text-decoration: none;
		background-color: #fff;
		font-weight: 600;
	}
	
	/*&,
	&>a:last-child {
	  border-bottom-left-radius: var(--bs-border-radius);
	  border-bottom-right-radius: var(--bs-border-radius);
	}*/
}

.bi-empty {
	width: 16px;
	height: 24px;
	display: block;
}

.td-none {
	text-decoration: none;
}

.btn {
	border-radius: 100px;
}

.btn-nr {
	border-radius: var(--bs-border-radius);
}

header .btn-nr {
	border-radius: 0;
}

.bg-primary.bg-darker,
.btn-primary.bg-darker {
	background-color: darken($primary, $amount: 15);
	border-color: darken($primary, 10);
}

.category a.ci-0 {
	background: rgba(81, 160, 210, 0.20);
}

footer {
	background: #333;
	border-top: 6px solid var(--bs-primary);
	color: #fff;
	
	a {
		color: #fff;
	}
}

.range {
	
	& .range-price-from input,
	& .range-price-to input {
		
		padding: 2px;
		background: #e9e9e9;
		border: none;
		//padding: 3px;
		text-align: center;
		
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		
		-moz-appearance: textfield;
	}
}

.range-slider {
	height: 5px;
	position: relative;
	background-color: #e1e9f6;
	border-radius: 2px;
}

.range-selected {
	height: 100%;
	left: 0%;
	right: 0%;
	position: absolute;
	border-radius: 5px;
	background-color: #1b53c0;
}

.range-input {
	position: relative;
	
	input {
		position: absolute;
		width: 100%;
		height: 5px;
		top: -7px;
		background: none;
		pointer-events: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}
}

.range-input input::-webkit-slider-thumb {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 3px solid #1b53c0;
	background-color: #fff;
	pointer-events: auto;
	-webkit-appearance: none;
}

.range-input input::-moz-range-thumb {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	border: 3px solid #1b53c0;
	background-color: #fff;
	pointer-events: auto;
	-moz-appearance: none;
}

.range-price {
	margin: 30px 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	
	label {
		margin-right: 5px;
	}
	
	input {
		width: 40px;
		padding: 5px;
	}
	
	input:first-of-type {
		margin-right: 15px;
	}
}

.setfilter-btn:hover {
	color: #fff;
}

.product-description {
	text-align: justify;
	text-justify: newspaper;
}

.subcats a {
	background: #eceff1;
	text-transform: capitalize;
	text-decoration: none;
	color: #4f4f4f;
	border-radius: var(--bs-border-radius);
	padding-top: .3rem;
	padding-bottom: .3rem;
	padding-left: .5rem;
	padding-right: .5rem;
	transition: all 0.25s;
	font-weight: 600;
	// font-size: 12px;
	
	&:hover {
		background: #fff;
		box-shadow: 0 2px 5px #ffffff3d, 0 3px 10px 5px rgba(0, 0, 0, 0.05);
	}
}

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(md) {
	.product-box img {
		max-height: 300px;
		height: 300px;
	}
}

@include media-breakpoint-up(sm) {

}

.ac-box.show {
	display: block !important;
	
	position: fixed;
	width: 100%;
	height: 100vh;
	backdrop-filter: blur(5px) brightness(1.2);
	
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	
	+ .main-content-box {
		margin-top: 20vh;
	}
	
	&:empty {
		display: none;
	}
}

@include media-breakpoint-up(xs) {
	
	.product-card .card-img-top {
		height: 100px;
	}
	
	.product-card .product-title a,
	.product-card .product-price {
		font-size: 1rem;
	}
	
	.product-card .product-price {
		margin-bottom: 10px;
	}
	
	:root {
		--bs-body-line-height: 1.25;
	}
	
	.product-card .card-footer {
		flex-direction: column;
	}
}
